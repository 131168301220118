import React, { useState, createContext, useEffect, useCallback } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { appendScript } from "../../assets/js/helper"

const AppContext = createContext()

const AppProvider = ({ children }) => {
  // const data = useStaticQuery(getEnvs)
  // const {
  //   // whereWeServiceSlug,
  //   // whereWeServiceLabel,
  //   // whereWeServiceActive,
  //   // pestLibrarySlug,
  //   // pestLibraryLabel,
  //   // pestLibraryActive,
  //   siteUrl,
  //   // recapSecret,
  //   recapKey,
  //   blogParent,
  // } = data.nodeEnvs

  // const { company_name, primary_logo, corporate_head_office } =
  //   data.kontentItemSettings.elements

  const [size, setSize] = useState(null)
  const [height, setHeight] = useState(null)
  const [isNavOpen, setNav] = useState(false)
  const [fullName, setFullName] = useState("")
  const [email, setEmail] = useState("")
  const [
    isPRClientAndJQueryScriptsLoaded,
    setIsPRsClientAndJQueryScriptsLoaded,
  ] = useState(false) // PR = pestroutes

  useEffect(() => {
    if (window) {
      setSize(window.innerWidth)
      setHeight(window.pageYOffset)
      window.addEventListener("resize", () => {
        setSize(window.innerWidth)
      })
      window.addEventListener("scroll", () => {
        setHeight(window.pageYOffset)
      })
    }

    return () => {
      window.removeEventListener("resize", () => {})
      window.removeEventListener("scroll", () => {})
    }
  }, [])

  const handleLoadingPRClientAndJQueryScripts = useCallback(isLoaded => {
    setIsPRsClientAndJQueryScriptsLoaded(isLoaded)
  }, [])

  const loadPestRoutesClient = useCallback(() => {
    if (isPRClientAndJQueryScriptsLoaded) return

    appendScript({
      id: "jquery-3.6.0.min.js",
      scriptToAppend: "https://code.jquery.com/jquery-3.6.0.min.js",
      isAsync: false,
    })

    appendScript({
      id: "pestroutesClient.js",
      scriptToAppend:
        "https://lmk.pestroutes.com/resources/js/lobster/pestroutesClient.js",
      isAsync: true,
    })
  }, [isPRClientAndJQueryScriptsLoaded])

  const handleOpenNav = useCallback(() => {
    setNav(true)
  }, [])

  const handleCloseNav = useCallback(() => {
    setNav(false)
  }, [])

  const setName = useCallback(name => {
    setFullName(name)
  }, [])

  const setEmailAddress = useCallback(emailAddress => {
    setEmail(emailAddress)
  }, [])

  return (
    <AppContext.Provider
      value={{
        size,
        height,
        handleCloseNav,
        handleOpenNav,
        isNavOpen,
        whereWeServiceActive: false,
        whereWeServiceLabel: "",
        whereWeServiceSlug: "",
        pestLibrarySlug: "",
        pestLibraryLabel: "",
        pestLibraryActive: false,
        blogParent: "",
        siteUrl: "",
        recapSecret: "",
        recapKey: "",
        fullName,
        setName,
        email,
        setEmailAddress,
        company_name:"",
        primary_logo: {},
        corporate_head_office:"",
        isPRClientAndJQueryScriptsLoaded,
        loadPestRoutesClient,
        handleLoadingPRClientAndJQueryScripts,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

// export const getEnvs = graphql`
//   query Context {
//     nodeEnvs {
//       # whereWeServiceActive
//       # whereWeServiceSlug
//       # whereWeServiceLabel
//       # pestLibrarySlug
//       # pestLibraryLabel
//       # pestLibraryActive
//       siteUrl
//       # recapSecret
//       recapKey
//       blogParent
//     }
//     kontentItemSettings {
//       elements {
//         company_name {
//           value
//         }
//         # primary_logo {
//         #   value {
//         #     url
//         #   }
//         # }
//         # corporate_head_office {
//         #   value {
//         #     ... on kontent_item_office {
//         #       id
//         #       elements {
//         #         service_pages {
//         #           value {
//         #             ... on kontent_item_page {
//         #               id
//         #               elements {
//         #                 page_title {
//         #                   value
//         #                 }
//         #                 slug {
//         #                   value
//         #                 }
//         #               }
//         #             }
//         #           }
//         #         }
//         #         price_range {
//         #           value
//         #         }
//         #         business_hours {
//         #           value {
//         #             ... on kontent_item_day {
//         #               id
//         #               elements {
//         #                 day_of_the_week {
//         #                   value {
//         #                     name
//         #                   }
//         #                 }
//         #                 open {
//         #                   value
//         #                 }
//         #                 close {
//         #                   value
//         #                 }
//         #                 closed {
//         #                   value {
//         #                     name
//         #                   }
//         #                 }
//         #               }
//         #             }
//         #           }
//         #         }
//         #       }
//         #     }
//         #   }
//         # }
//       }
//     }
//   }
// `

export { AppProvider, AppContext }
