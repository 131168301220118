import type { NavigationList, StrapiNavigationItem } from "./navigation-types"

export const getFormattedNavigation = (
  navigationItems: StrapiNavigationItem[]
): NavigationList => {

  const navigationList = navigationItems.map(navItem => {
    const childPages =
      navItem?.sub_navigation_item?.map(childPage => {

        return {
          id: childPage?.id,
          linkText: childPage?.sub_navigation_link_text ?? "",
          externalLink: childPage?.sub_navigation_external_page ?? "",
          slug: childPage?.page?.slug ?? "",
          svg_icon: childPage?.sub_navigation_svg_icon ?? "",
          migratedUrl: childPage?.migrated_url ?? ""
        }
      }) ?? []
    return {
      id: navItem.id,
      childPages,
      externalLink: navItem?.external_link ?? "",
      linkText: navItem?.link_text ?? "",
      slug: navItem?.page?.slug ?? "",
      migratedUrl: navItem?.migrated_url ?? ""
    }
  })

  return navigationList
}
