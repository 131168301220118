module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"BCL Yard Services, LLC","short_name":"BCL Yard Services, LLC","start_url":"/","theme_color":"#fff","background_color":"#fff","display":"standalone","icon":"src/images/lobster512.png","include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"5c6ad25815ffb72e89b673b2d99f2da1"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.bclyardservices.com","noQueryString":true},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager-timeout/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-K5VFMGB","includeInDevelopment":false,"timeout":5000,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"page-change"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","exclude":["/dev-404-page/","/404/","/404.html","/offline-plugin-app-shell-fallback/"],"crumbLabelUpdates":[{"pathname":"/blog","crumbLabel":"Blog"},{"pathname":"/where-we-service","crumbLabel":"Where We Service"}]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-70},
    },{
      plugin: require('../../gatsby-theme-saddleback/gatsby-browser.js'),
      options: {"plugins":[],"projectId":"9f4b1050-42a7-0062-8dc4-0cdf56255769","authorizationKey":"ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogImU0ZGRhYjZlYmJiZDQ4Nzk5YzkxOTdkZWU1N2M5Zjc5IiwNCiAgImlhdCI6ICIxNjIxODc2NjczIiwNCiAgImV4cCI6ICIxOTY3NDc2NjczIiwNCiAgInByb2plY3RfaWQiOiAiOWY0YjEwNTA0MmE3MDA2MjhkYzQwY2RmNTYyNTU3NjkiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.YaL12yA5wkNb9-Pi6xqFOe2JeE06TB65qmd83Q9_5b4","usePreviewUrl":"false","whereWeServicePathName":"where-we-service","whereWeServiceBreadcrumb":"Where We Service","siteUrl":"https://www.bclyardservices.com","title":"BCL Yard Services, LLC","description":"BCL Yard Services, LLC","domainVerification":"1b9PHSydiUCXjFiG80dmTKQ7jrR9Onsr7IoJBwlM-wk","gtmId":"GTM-K5VFMGB","compassID":"c46bfbd88e8635b80151b5900237cf2ea035bcfd8f268f57a1f7c10a4642acdf"},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
