import React, { useMemo, createContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import {
  NavigationSettingsContextData,
  NavigationSettingsProviderProps,
} from "./navigation-types"
import { getFormattedNavigation } from "./getFormattedNavigation"

const NavigationSettingsContext = createContext<NavigationSettingsContextData>(
  {} as NavigationSettingsContextData
)

const NavigationSettingsProvider = ({
  children,
}: NavigationSettingsProviderProps) => {
  const data = useStaticQuery(getNavigationSettingsData)

  const { allStrapiNavigation } = data

  const {
    header_navigation,
    footer_navigation,
    secondary_header_navigation,
    side_navigation,
    credits_navigation,
  } = allStrapiNavigation.nodes[0]

  const headerNavigation = useMemo(() => {
    if (!header_navigation || header_navigation.length === 0) return []
    const formattedHeaderNavItems = getFormattedNavigation(header_navigation)
    return formattedHeaderNavItems
  }, [header_navigation])

  const footerNavigation = useMemo(() => {
    if (!footer_navigation || footer_navigation.length === 0) return []
    const formattedFooterNavItems = getFormattedNavigation(footer_navigation)
    return formattedFooterNavItems
  }, [footer_navigation])

  const secondaryHeaderNavigation = useMemo(() => {
    if (
      !secondary_header_navigation ||
      secondary_header_navigation.length === 0
    )
      return []
    const formattedSecondaryHeaderNavItems = getFormattedNavigation(
      secondary_header_navigation
    )
    return formattedSecondaryHeaderNavItems
  }, [secondary_header_navigation])

  const sideNavigation = useMemo(() => {
    if (!side_navigation || side_navigation.length === 0) return []
    const formattedSideNavItems = getFormattedNavigation(side_navigation)
    return formattedSideNavItems
  }, [side_navigation])

  const creditsNavigation = useMemo(() => {
    if (!credits_navigation || credits_navigation.length === 0) return []
    const formattedCreditsNavItems = getFormattedNavigation(credits_navigation)
    return formattedCreditsNavItems
  }, [credits_navigation])

  return (
    <NavigationSettingsContext.Provider
      value={{
        headerNavigation,
        footerNavigation,
        secondaryHeaderNavigation,
        sideNavigation,
        creditsNavigation,
      }}
    >
      {children}
    </NavigationSettingsContext.Provider>
  )
}

export const strapiQuery = graphql`
  fragment StrapiNavigationFragment on STRAPI__COMPONENT_NAVIGATION_NAVIGATION_ITEM {
    id
    external_link
    link_text
    migrated_url
    page {
      slug
    }
    svg_icon
    sub_navigation_item {
      id
      migrated_url
      sub_navigation_external_page
      sub_navigation_link_text
      sub_navigation_svg_icon
      page {
        slug
      }
    }
  }
`

export const getNavigationSettingsData = graphql`
  query NavigationSettingsContext {
    allStrapiNavigation {
      nodes {
        credits_navigation {
          ...StrapiNavigationFragment
        }
        header_navigation {
          ...StrapiNavigationFragment
        }
        secondary_header_navigation {
          ...StrapiNavigationFragment
        }
        side_navigation {
          ...StrapiNavigationFragment
        }
      }
    }
  }
`

export { NavigationSettingsProvider, NavigationSettingsContext }
