import React, { ReactNode } from "react"
import { AppProvider as AppProviderV1 } from "components/context/context"
import { SettingsProvider } from "./settings"
// import { ExternalScriptsProvider } from "./ExternalScriptsContext"
// import { FormFieldsProvider } from "./FormFieldsContext"
// import { SideNavProvider } from "./SideNavContext"
// import { WindowDimensionsProvider } from "./WindowDimensionsContext"

interface AppProviderProps {
  children: ReactNode
}

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <SettingsProvider>
      {/* <ExternalScriptsProvider> */}
      {/* <SideNavProvider> */}
      {/* <FormFieldsProvider> */}
      {/* <WindowDimensionsProvider> */}
      <AppProviderV1>{children}</AppProviderV1>
      {/* </WindowDimensionsProvider> */}
      {/* </FormFieldsProvider> */}
      {/* </SideNavProvider> */}
      {/* </ExternalScriptsProvider> */}
    </SettingsProvider>
  )
}
