import React from "react"
import { ReactNode } from "react"
import { GeneralSettingsProvider } from "./GeneralSettingsContext/GeneralSettingsContext"
import { IntegrationsSettingsProvider } from "./Integrations/IntegrationsSettingsContext"
import { ColorAreasSettingsProvider } from "./ColorAreas/ColorAreasSettingsContext"
import { NavigationSettingsProvider } from "./Navigation/NavigationSettingsContext"

interface SettingsProviderProps {
  children: ReactNode
}

const SettingsProvider = ({ children }: SettingsProviderProps) => {
  return (
    <GeneralSettingsProvider>
      <IntegrationsSettingsProvider>
        <ColorAreasSettingsProvider>
          <NavigationSettingsProvider>
            <>{children}</>
          </NavigationSettingsProvider>
        </ColorAreasSettingsProvider>
      </IntegrationsSettingsProvider>
    </GeneralSettingsProvider>
  )
}

export { SettingsProvider }
